import React from "react";
// import {
//     default as vegaEmbed
// } from 'vega-embed';

import vegaEmbed from "vega-embed";

const vegaChartStyle = {
  /* styles skipped for brevity */
};

export default class VegaChart extends React.Component {
  static defaultProps = {
    specUrl: "",
  };

  state = {
    specUrl: this.props.specUrl,
  };

  componentDidMount() {
    console.log(
      `VegaChart.componentDidMount ${JSON.stringify(this.props.chartName)}`
    );
    const { chartName, specUrl } = this.props;
    const chartId = `chart-${chartName}`;
    const config = {
      actions: false,
    };

    if (specUrl) {
      vegaEmbed(`#${chartId}`, specUrl, config);
    }
  }

  componentDidUpdate() {
    console.log(
      `VegaChart.componentDidUpdate ${JSON.stringify(this.props.chartName)}`
    );
    const { chartName, specUrl } = this.props;
    const chartId = `chart-${chartName}`;
    const config = {
      actions: false,
    };

    vegaEmbed(`#${chartId}`, specUrl, config);
  }

  componentWillMount() {
    console.log(
      `VegaChart.componentWillMount ${JSON.stringify(this.props.chartName)}`
    );
  }

  render() {
    // let { specUrl } = this.state;
    let chartName = this.props.chartName;
    const chartId = `chart-${chartName}`;
    // console.log(`chart id is: ${chartId}, ${specUrl}`);

    // if (specUrl) {
    //     // var sp = "https://raw.githubusercontent.com/vega/vega/master/docs/examples/bar-chart.vg.json";
    //     const spec = {
    //         actions: false
    //     }
    //     vegaEmbed(`#${chartId}`, specUrl, spec);
    //     // vegaEmbed('#chart', sp, spec);
    // }
    return (
      <div style={vegaChartStyle}>
        <div id={chartId} />
      </div>
    );
  }
}
