import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import Layout from './../components/Layouts';
import BlogPostChrome from "../components/BlogPostChrome"
import rehypeReact from "rehype-react";
import Counter from "../components/Counter";
import VegaChart from "../components/VegaChart";
import "katex/dist/katex.min.css"

const renderAst = new rehypeReact({
 createElement: React.createElement,
 components: { 
   "interactive-counter": Counter,
   "vega-chart": VegaChart
  },
}).Compiler;

class mdBlogPost extends React.Component {
  render() {
    // console.log(this.props.data.markdownRemark);
    const { htmlAst, frontmatter } = this.props.data.markdownRemark

    return (
      <Layout data={this.props.data} location={this.props.location}>
      <BlogPostChrome {...frontmatter}>
        <div className="container content"  style={{maxWidth: 800, margin: "0 auto"}}>
          {
            renderAst(htmlAst)
          }        
        </div>
      </BlogPostChrome>
      </Layout>
    )
  }
}

export default mdBlogPost

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      ...MarkdownBlogPost_frontmatter
    }
  }
`